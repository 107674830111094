import React from 'react'

import Layout from '../../components/Layout'



export default class SpiderIdentifierIndexPage extends React.Component {
  
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/sun.png')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              color: '#6e3f95',
              padding: '1rem',
            }}
          >
            How To Indentify Common Michigan Spiders
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <h3>Wolf Spider</h3>
              <img style={{borderRadius: '5px'}} src={'/img/spiders/wolf_spider.jpeg'} alt={'Wolf Spider In Michigan'} />
              <p>Wolf spiders are commonly found around doors, windows, house plants,  basements, and garages. 
                Wolf spiders are often confused with the brown recluse, but are missing the distinctive violion shaped marking.</p>
              

                <h3>Brown Recluse</h3>
                <img style={{borderRadius: '5px'}} src={'/img/spiders/brown_recluse.jpeg'} alt={'Brown Recluse In Michigan'} />
                <p>The Brown Recluse is golden brown in color and commonly lives in basements and  garages of houses. 
                The spider often hides behind boards and boxes. Bites occur when people are rummaging around in cabinets.</p>

                
                <h3>Black Widow</h3>
                <img style={{borderRadius: '5px'}} src={'/img/spiders/black_widow.jpeg'} alt={'Black Widow In Michigan'} />
                <p>In Michigan, northern black widows are more common in the Lower Peninsula, according to Michigan State University's Diagnostic Services website. 
                They tend to live in hollow logs, piles of brush and in the corners of sheds and crawlspaces.</p>

                
                <h3>Fishing Spider</h3>
                <img style={{borderRadius: '5px'}} src={'/img/spiders/fishing_spider.jpeg'} alt={'Fishing Spider In Michigan'} />
                <p>Fishing spiders are similar to large wolf spiders in size, shape and color.  
                Fishing spiders get their name from living near water and may catch small fish and aquatic insects. 
                But, these spiders also can live in forests or wooded areas and frequently invade houses.
                </p>

                <h3>Jumping Spider</h3>
                <img style={{borderRadius: '5px'}} src={'/img/spiders/jumping_spider.jpeg'} alt={'Jumping Spider In Michigan'} />

                <p>Jumping spiders also do not tend to spin webs, instead making silken shelters under leaves or bark.
                  This way, they can more easily hunt their prey, which they do during the day by sneaking up and leaping on the victim.
                These spiders are known to have the best vision of all spiders, which is important for their hunting ability.</p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}